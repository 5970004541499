@import url(https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);

.container {
  display: flex;
  justify-content: center;
  align-items: center; }
  .container .card {
    background-color: black;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    margin-top: 100px;
    color: greenyellow; }
    .container .card h3 {
      line-height: 1.6; }
    .container .card h4 {
      line-height: 1.6; }
    @media (max-width: 800px) {
      .container .card h1 {
        font-size: 20px; }
      .container .card h3 {
        font-size: 12px;
        line-height: 1.6; }
      .container .card h4 {
        font-size: 10px;
        line-height: 1.6; } }
    .container .card .profile-pic {
      height: 20%;
      width: 20%;
      border-radius: 1000px;
      border: 5px solid yellowgreen;
      margin-top: 20px; }
      @media (max-width: 800px) {
        .container .card .profile-pic {
          height: 50%;
          width: 50%; } }

.container {
  display: flex;
  flex-direction: column; }
  .container .skills-card {
    background-color: yellowgreen;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: black; }
    @media (max-width: 800px) {
      .container .skills-card h1 {
        font-size: 20px; } }
    .container .skills-card h3 {
      text-decoration: underline; }
      @media (max-width: 800px) {
        .container .skills-card h3 {
          font-size: 15px; } }
    .container .skills-card .skills {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      @media (max-width: 800px) {
        .container .skills-card .skills {
          justify-content: center; } }
      .container .skills-card .skills .skill {
        padding: 20px;
        margin: 20px; }
        @media (max-width: 800px) {
          .container .skills-card .skills .skill p {
            font-size: 10px; } }
        .container .skills-card .skills .skill .icon {
          font-size: 150px; }
          @media (max-width: 800px) {
            .container .skills-card .skills .skill .icon {
              font-size: 80px; } }
        @media (max-width: 800px) {
          .container .skills-card .skills .skill {
            padding: 0px;
            margin: 5px; } }

.container .projects-card {
  background-color: black;
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: yellowgreen;
  line-height: 1.6; }
  @media (max-width: 800px) {
    .container .projects-card h1 {
      font-size: 20px; } }
  @media (max-width: 800px) {
    .container .projects-card .project h3 {
      font-size: 13px; } }
  .container .projects-card .project .iconP {
    font-size: 30px; }
    @media (max-width: 800px) {
      .container .projects-card .project .iconP {
        font-size: 15px; } }
  .container .projects-card .project p {
    margin: 10px; }
    @media (max-width: 800px) {
      .container .projects-card .project p {
        font-size: 10px; } }
  .container .projects-card .project .project-img {
    width: 50%;
    border: 1px solid yellowgreen; }
    @media (max-width: 800px) {
      .container .projects-card .project .project-img {
        width: 70%; } }
  .container .projects-card .project .project-img-servable {
    width: 20%;
    border: 1px solid yellowgreen; }
    @media (max-width: 800px) {
      .container .projects-card .project .project-img-servable {
        width: 50%; } }
  .container .projects-card .project .desc-stack {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .container .projects-card .project .desc-stack .description {
      text-align: center;
      width: 48%; }
      @media (max-width: 800px) {
        .container .projects-card .project .desc-stack .description {
          width: 100%; } }
    .container .projects-card .project .desc-stack .stack {
      text-align: center;
      width: 48%; }
      @media (max-width: 800px) {
        .container .projects-card .project .desc-stack .stack {
          width: 100%; } }

.container .contact-card {
  background-color: yellowgreen;
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: black; }
  @media (max-width: 800px) {
    .container .contact-card h3 {
      font-size: 13px; } }
  .container .contact-card .icon {
    font-size: 50px;
    padding: 20px; }
    @media (max-width: 800px) {
      .container .contact-card .icon {
        font-size: 40px; } }

.App {
  text-align: center; }

.App-logo {
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #09d3ac; }

.designs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif; }
  .designs-container .designs-card {
    background-color: black;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: yellowgreen;
    line-height: 1.6; }
    .designs-container .designs-card img {
      width: 50%;
      border: 1px solid yellowgreen; }
      @media (max-width: 800px) {
        .designs-container .designs-card img {
          width: 70%; } }
    .designs-container .designs-card h1 {
      font-family: 'Press Start 2P', cursive; }
      @media (max-width: 800px) {
        .designs-container .designs-card h1 {
          font-size: 20px; } }
    .designs-container .designs-card h2, .designs-container .designs-card h3 {
      font-family: 'Press Start 2P', cursive; }
    .designs-container .designs-card p {
      margin: 20px;
      text-align: justify; }
      .designs-container .designs-card p #icon {
        font-size: larger; }

html {
  scroll-behavior: smooth; }

body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body a {
    color: inherit; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

