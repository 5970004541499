.designs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;

  .designs-card {
    background-color: black;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: yellowgreen;
    line-height: 1.6;

    img {
      width: 50%;
      border: 1px solid yellowgreen;

      @media (max-width: 800px) {
        width: 70%;
      }
    }

    h1 {
      font-family: 'Press Start 2P', cursive;
      @media (max-width: 800px) {
        font-size: 20px;
      }
    }

    h2, h3 {
      font-family: 'Press Start 2P', cursive;
    }
  
    p {
      margin: 20px;
      text-align: justify;

      #icon {
        font-size: larger;
      }
    }
  }

}