.container {
  display: flex;
  flex-direction: column;
  .skills-card {
    background-color: yellowgreen;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: black;

    h1 {
      @media (max-width: 800px) {
        font-size: 20px;
      }
    }

    h3 {
      text-decoration: underline;
      @media (max-width: 800px) {
        font-size: 15px;
      }
    }

    .skills {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media (max-width: 800px) {
        justify-content: center;
      }

      .skill {
        padding: 20px;
        margin: 20px;

        p {
          @media (max-width: 800px) {
            font-size: 10px;
          }
        }

        .icon {
          font-size: 150px;

          @media (max-width: 800px) {
            font-size: 80px;
          }
        }

        @media (max-width: 800px) {
          padding: 0px;
          margin: 5px;
        }
      }
    }
  }
}
