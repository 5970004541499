.container {
  .contact-card {
    background-color: yellowgreen;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: black;

    h3 {
      @media (max-width: 800px) {
        font-size: 13px;
      }
    }

    .icon {
      font-size: 50px;
      padding: 20px;

      @media (max-width: 800px) {
        font-size: 40px;
      }
    }
  }
}
